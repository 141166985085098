import Footer from "../../components/footer/Footer";
import PreviousQuestionButton from "./header/PreviousQuestionButton";
import Question from "./question/Question";
import "./application.scss";
import Summary from "./templates/Summary";
import Overlay from "components/ui/loading-spinners/Overlay/Overlay";
import { useGetApplicationByIdQuery, useSetApplicationStepAnswerMutation } from "./applicationService";
import { useSelector, useDispatch } from "react-redux";
import { getCurrentUserApplicationId, setLogout, getVersion, setNhApplicationStatus } from "features/authSlice";
import { getStepData } from "components/question/questionSlice";
import { useNavigate } from "react-router-dom";
import { userVersionFeatureMap, UserVersion, UserFeature } from "utilities/UserversionFeatures";
import { Loader } from "components/Loader/Loader";
import usePerformRouting from "features/PerformRouting";
import { useEffect } from "react";

const ApplicationContainer = () => {
  const stepData = useSelector(getStepData);
  const applicationId = useSelector(getCurrentUserApplicationId);
  const version = useSelector(getVersion);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isFetching, isError: isGetApplicationByIdError, error: getApplicationByIdError } = useGetApplicationByIdQuery(applicationId);
  const [_, { isLoading, isError: isSetApplicationStepAnswerError, error: setApplicationStepAnswerError }] = useSetApplicationStepAnswerMutation({
    fixedCacheKey: "updateApplication",
  });
  const { performRouting } = usePerformRouting();
  useEffect(() => {
    performRouting();
  }, []);

  function getApplicationStepAnswerError() {
    if (
      typeof setApplicationStepAnswerError === "object" &&
      "status" in setApplicationStepAnswerError &&
      setApplicationStepAnswerError.status === 401
    ) {
      dispatch(setLogout());
      navigate("/", { state: { previousPath: "/application" } });
    }
  }
  function getApplicationIdError() {
    if (typeof getApplicationByIdError === "object" && "status" in getApplicationByIdError && getApplicationByIdError.status === 401) {
      dispatch(setLogout());
      navigate("/", { state: { previousPath: "/application" } });
    }
  }
  if (isGetApplicationByIdError) {
    getApplicationIdError();
  } else if (isSetApplicationStepAnswerError) {
    getApplicationStepAnswerError();
  }
  const versionFeatures = userVersionFeatureMap[version.toUpperCase() as UserVersion];
  //for V2 user => dashboard , V1 user => Thankyou page
  if (stepData.stepCodeText === "EndOfFlow") {
    if (versionFeatures?.includes(UserFeature.SHOW_DASHBOARD)) {
      const nhApplicationStatus = "Completed";
      dispatch(setNhApplicationStatus(nhApplicationStatus));
      setTimeout(() => {
        navigate("/dashboard");
      }, 10000);
      return (
        <div className="loader-dashboard">
          <Loader message1="One moment!" message2="Creating your custom dashboard" />
        </div>
      );
    }
  }

  if (isFetching || isLoading) {
    return <Overlay />;
  }

  if (!stepData) {
    throw new Error("No data");
  }
  const isSummary = stepData?.stepType === "summary";

  return (
    <div className="application">
      {!isSummary && <PreviousQuestionButton />}
      {isSummary ? <Summary /> : <Question />}
      <Footer />
    </div>
  );
};

export default ApplicationContainer;
