import { useEffect } from "react";
import { useDeleteFailedDocumentsMutation } from "pages/dashboard/home/dashboardService";
import { useSelector } from "react-redux";
import { getApplicationRoutingData } from "features/authSlice";
import Overlay from "components/ui/loading-spinners/Overlay/Overlay";
import usePerformRouting from "features/PerformRouting";

export default function AuthRouting() {
  const [removeFailedDocuments, { isSuccess: isDeleteFailedDocumentsSuccess }] = useDeleteFailedDocumentsMutation();
  const { nhDealApplicationId } = useSelector(getApplicationRoutingData);

  const { isSuccess, performRouting } = usePerformRouting();

  useEffect(() => {
    if (isSuccess && isDeleteFailedDocumentsSuccess) {
      performRouting();
    }
  }, [isSuccess, isDeleteFailedDocumentsSuccess]);

  useEffect(() => {
    void (async () => {
      if (nhDealApplicationId) {
        await removeFailedDocuments(nhDealApplicationId);
      }
    })();
  }, [nhDealApplicationId, removeFailedDocuments]);

  return <Overlay />;
}
