import { useSelector } from "react-redux";
import { getLeadProvider, getCurrentUserApplicationId, getNhDealApplicationId, getCurrentUserId } from "features/authSlice";
import { LeadProvider } from "pages/registration/registrationService";
import WelcomeVersonOne from "./WelcomeVOne";
import WelcomeMultiPath from "./WelcomeMultiPath";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useGetInterimDashboardQuery } from "pages/application/applicationService";
import { LoadingOverlay } from "components/ui/loading-spinners";

export default function Welcome() {
  const leadProvider = useSelector(getLeadProvider);
  const userId = useSelector(getCurrentUserId);
  const id = useSelector(getNhDealApplicationId);
  const isLPMicrosite = () => leadProvider.toLowerCase() == LeadProvider.Microsite.toLowerCase();
  const { data: hasInterimDashboard, isLoading, isFetching } = useGetInterimDashboardQuery({id, userId});
  const applicationId = useSelector(getCurrentUserApplicationId);
  const navigate = useNavigate();
  useEffect(() => {
    if (!isLoading) {
      if (hasInterimDashboard) {
        navigate("/dashboard", { replace: true });
      } else if (applicationId) {
        navigate("/application", { replace: true });
      }
    }
  }, [applicationId, hasInterimDashboard, navigate, isLoading]);
  if (isLoading || isFetching){
    return <LoadingOverlay/>
  }

  return <>{isLPMicrosite() ? <WelcomeVersonOne /> : <WelcomeMultiPath />}</>;
}
