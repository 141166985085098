import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { LoginResponse, loginApi } from "./loginService";
import { RootState } from "store";
import { MfaResponse, mfaApi } from "pages/MFA/mfaService";
import { ValidateOtpResponse, authenticationApi } from "pages/authentication/authenticationService";

interface MfaData {
  loginResponse: LoginResponse;
  mfaResponse: MfaResponse;
  resendCodeDeviceId: string;
}

const initialState: MfaData = {
  loginResponse: {
    flowId: "",
    _embedded: {
      devices: [
        {
          id: "",
          type: "",
          phone: "",
          email: "",
        },
      ],
    },
    status: "",
  },
  mfaResponse: {
    id: "",
  },
  resendCodeDeviceId: "",
};

const defaultValueOfLoginAuth = initialState;

export const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    setResendCodeDeviceId: (state, action: PayloadAction<string>) => {
      state.resendCodeDeviceId = action.payload;
    },
    setAuthDetails: (state, action: PayloadAction<MfaData>) => {
      state.loginResponse = action.payload.loginResponse;
      state.mfaResponse = action.payload.mfaResponse;
      state.resendCodeDeviceId = action.payload.resendCodeDeviceId;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(loginApi.endpoints.postLogin.matchFulfilled, (state, action: PayloadAction<LoginResponse | ValidateOtpResponse>) => {
      if ("_embedded" in action.payload) {
        state.loginResponse = action.payload;
      }
    });

    builder.addMatcher(mfaApi.endpoints.postMfa.matchFulfilled, (state, action: PayloadAction<MfaResponse>) => {
      state.mfaResponse = action.payload;
    });

    builder.addMatcher(authenticationApi.endpoints.postOtpValidation.matchFulfilled, (state) => {
      state.loginResponse = defaultValueOfLoginAuth.loginResponse;
      state.mfaResponse = defaultValueOfLoginAuth.mfaResponse;
      state.resendCodeDeviceId = defaultValueOfLoginAuth.resendCodeDeviceId;
    });
  },
});

export const getAuthDevicesDetails = (state: RootState): LoginResponse => state.login.loginResponse;
export const getAuthOtpDetails = (state: RootState): MfaResponse => state.login.mfaResponse;
export const getResendCodeDeviceId = (state: RootState): string => state.login.resendCodeDeviceId;

export const { setAuthDetails, setResendCodeDeviceId } = loginSlice.actions;

export default loginSlice.reducer;
