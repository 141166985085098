/* eslint-disable */
import LoanCard from "components/loan-card/LoanCard";
import { MyLoanInfo, useGetMyLoansQuery } from "pages/application/applicationService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import "./my-loans.scss";
import Footer from "components/footer/Footer";
import { getCurrentUserId, setApplicationRoutingData } from "features/authSlice";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { formatDate } from "utilities/date";
import { useEffect } from "react";
import Overlay from "components/ui/loading-spinners/Overlay/Overlay";

export default function MyLoans() {
  const userId = useSelector(getCurrentUserId);
  const { data, isLoading, isFetching } = useGetMyLoansQuery(userId);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pathsToShowMyLoans = ["/application", "/dashboard"];

  useEffect(() => {
    if (data && data.length == 1) {
      if (
        !(
          (
            location.state != null &&
            typeof location.state === "object" &&
            "previousPath" in location.state &&
            pathsToShowMyLoans.some((str) => location.state.previousPath.includes(str))
          ) // eslint-disable-line
        )
      ) {
        navigateToPostAuth(0, data);
      }
    }
  }, [data]);

  const navigateToPostAuth = (index: number, loans: MyLoanInfo[]) => {
    const {
      nhApplicationId,
      nhApplicationStatus,
      nhDealApplicationId,
      applicationRoleType,
      dealApplicationId,
      loanId,
      hasPal,
      prospectId,
      loanPurpose,
    } = loans[index];
    dispatch(
      setApplicationRoutingData({
        nhApplicationId,
        nhApplicationStatus,
        nhDealApplicationId,
        applicationRoleType,
        dealApplicationId,
        loanId,
        hasPal,
        prospectId,
        loanPurpose,
      })
    );
    navigate("/post-auth", { replace: true });
  };

  const handleLoanCardClick = (index: number) => {
    data && navigateToPostAuth(index, data);
  };

  if (isFetching || isLoading) {
    return <Overlay />;
  } else
    return (
      <div className="loans-section">
        <div className="loans-section__wrapper">
          <div className="loans-section__header">
            <div className="loans-section__header-icon-bg">
              <FontAwesomeIcon icon={solid("file-pen")} className="loans-section__header-icon" />
            </div>
            <span className="loans-section__header-title">My Loans</span>
          </div>
          <div className="loans-section__card-container">
            {data?.map((item, index) => {
              const { property, loanPurpose, loanAmount, status, startedDate, documentStatus } = item;
              return (
                <div className="loans-section__card" key={index}>
                  <LoanCard
                    property={property}
                    loanPurpose={loanPurpose}
                    loanAmount={loanAmount}
                    status={status}
                    startedDate={formatDate(startedDate)}
                    documentStatus={documentStatus}
                    handleLoanCardClick={handleLoanCardClick}
                    index={index}
                  />
                </div>
              );
            })}
          </div>
        </div>
        <Footer />
      </div>
    );
}
