import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_BFF_WEB_URL } from "utilities/Constant";

export interface FeatureFlag {
  cameraEnabled: boolean;
  imageTypesEnabled: boolean;
  newHeaderEnabled: boolean;
  insuranceAdEnabled: boolean;
  prStateEnabled: boolean;
  complianceServiceEnabled: boolean;
  allowedUploadFileTypes: string;
  preApprovalLetterEnabled: boolean;
  newLoginUIEnabled: boolean;
  insuranceQuickNewTabEnabled: boolean;
  insuranceAdForSubPropAddressEnabled:boolean;
  uQualSelfReferralAdLogicEnabled: boolean;
}

export const featureFlagApi = createApi({
  reducerPath: "featureFlagApi",
  baseQuery: fetchBaseQuery({ baseUrl: BASE_BFF_WEB_URL }),
  endpoints: (build) => ({
    getFeatureFlags: build.query<FeatureFlag, void>({
      query: () => `/bff/feature-flags`,
    }),
  }),
});

export const { useGetFeatureFlagsQuery } = featureFlagApi;
