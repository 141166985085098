import { Controller, useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { emailAddress, password_input } from "utilities/formSchemas";
import PasswordChecker from "components/password-and-confirm-password/passwordcheckerversion2/PasswordCheckerVersion2";
import { Button } from "components/ui/button/Button";
import Form from "components/ui/form/Form";
import { FormInput } from "components/ui/form/FormInput";
import { useEffect, useState } from "react";
import * as Constant from "utilities/Constant";
import homeGraphic from "assets/images/HomeGraphic.png";
import "./registration.scss";
import useDesktopChecker from "hooks/useDesktopChecker";
import { useDispatch } from "react-redux";
import { UpdatePasswordRequest, useGetDecryptEmailQuery, usePatchUpdatePasswordMutation } from "pages/registration/registrationService";
import { setUserId } from "features/authSlice";
import { ErrorType } from "utilities/error";
import { LoadingOverlay } from "components/ui/loading-spinners";
import Modal from "components/modal/Modal";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Consent } from "components/consent/Consent";
import { setFSUserProperties } from "utilities/full-story";
import { setResendCodeDeviceId } from "pages/login/loginSlice";

export const formSchema = z.object({
  email: emailAddress,
  password_input,
});

export default function RegistrationCoBorrower() {
  const {
    handleSubmit,
    control,
    register,
    formState: { errors, dirtyFields },
    setValue,
    getValues,
  } = useForm({
    resolver: zodResolver(formSchema),
  });

  const [error, setError] = useState(0);
  const [searchParams] = useSearchParams();
  const encryptedUser = searchParams.get("t")!;
  const {
    data: decryptedUserDetails,
    isLoading,
    isFetching,
  } = useGetDecryptEmailQuery(encryptedUser, {
    skip: !encryptedUser,
  });

  const navigate = useNavigate();
  useEffect(() => {
    if (decryptedUserDetails) {
      const { ex, em } = decryptedUserDetails;
      setFSUserProperties(em);
      if (ex !== true) {
        setIsBtnDisabled((prevState) => !prevState);
        setValue("email", em);
      } else {
        navigate("/", { replace: true });
      }
    }
  }, [decryptedUserDetails]);

  const [hasPwdError, setHasPwdError] = useState(false);
  const [isBtnDisabled, setIsBtnDisabled] = useState<boolean>(false);
  let showModal = false;
  if (!isLoading && !isFetching && !decryptedUserDetails) {
    showModal = true;
  }

  const isDesktop = useDesktopChecker();

  const [sendRequest, { data, isSuccess, isLoading: isRegistrationLoading }] = usePatchUpdatePasswordMutation();
  const dispatch = useDispatch();
  const onSubmit = (data: UpdatePasswordRequest) => {
    if (!hasErrors && !hasPwdError) {
      sendRequest(data)
        .unwrap()
        .catch((error: ErrorType) => {
          if (error.status == 409) {
            navigate("/", { replace: true });
          } else {
            setError(500);
          }
        });
    }
  };
  useEffect(() => {
    if (isSuccess && data) {
      setError(0);
      setFSUserProperties(getValues("email") as string, data.userLastName?.toLowerCase());
      dispatch(setUserId(data.userId));
      dispatch(setResendCodeDeviceId(data.deviceAuthId));
      navigate("/registrationAuth", { replace: true });
    }
  }, [isSuccess, data]);

  const ispasswordFieldModified = (dirtyFields.password_input as boolean) || false;
  const hasErrors = !!Object.keys(errors).length;
  if (isRegistrationLoading) return <LoadingOverlay text="Registering..." />;
  const formatContent = (content: string[]) => {
    return (
      <ul className="registration-list">
        {content?.map((line) => (
          <li key={line}>{line}</li>
        ))}
      </ul>
    );
  };

  return (
    <>
      {(isLoading || isFetching) && <LoadingOverlay text="Loading user details..." />}
      <div className="registration">
        {showModal && (
          <Modal
            defaultBody
            defaultBodyLabel="Something went wrong!"
            defaultBodyLabelIcon={regular("triangle-exclamation")}
            defaultBodySubContent={[
              "Please open the email we sent you and click on the link so that we can try again.",
              "We apologize for the inconvenience.",
            ]}
            footer={<></>}
          />
        )}
        <div className="registration__container">
          <div className="registration__form">
            <h1 className="registration__header">
              {" "}
              {isDesktop ? `Create an account to continue your application` : `Create an account to continue`}
            </h1>
            {error == 500 && (
              <div className="registration__error">
                {isDesktop
                  ? Constant.RegistrationError
                  : Constant.RegistrationError.map((error) => {
                      return <p key={error}>{error}</p>;
                    })}
              </div>
            )}
            <Form
              onSubmit={handleSubmit((data) => {
                onSubmit({
                  email: data.email as string,
                  password: data.password_input as string,
                  nhDealApplicationId: decryptedUserDetails?.nhDealAppId as string,
                });
              })}
            >
              <div className="row--item">
                <FormInput
                  formHandle={register("email")}
                  inputProps={{
                    name: "email",
                    placeholder: "Enter Your Email Address",
                    label: "Email Address",
                    errorMessage: errors.email?.message as string,
                    isReadOnly: true,
                  }}
                />
              </div>
              <div className="row--item">
                <Controller
                  name="password_input"
                  control={control}
                  render={({ field: { onChange } }) => (
                    <PasswordChecker
                      onChange={onChange}
                      checkErrors={ispasswordFieldModified}
                      createLabel="Create Password"
                      setHasPasswordError={setHasPwdError}
                      setBtnVisible={setIsBtnDisabled}
                      createPasswordPlaceholderName="Create password"
                    />
                  )}
                />
              </div>
              <Button title="Continue" className="registration__button" disabled={isBtnDisabled} />
            </Form>
          </div>
          {isDesktop && (
            <div className="registration__information">
              <p className="registration__text1">Better Path to Homeownership</p>
              <p className="registration__text2">Seamless, Secure, and Built for You</p>
              <p className="registration__text3">{formatContent(Constant.registrationInformation)}</p>
              <img src={homeGraphic} alt="login-graphic" className="registration__graphic" />
            </div>
          )}
        </div>
      </div>
      <Consent
        termsOfUseLink={Constant.TERMSOFUSELINK}
        privacyLink={Constant.PRIVACYLINK}
        partnersLink={Constant.PARTNERSLINK}
        agreementLink={Constant.AGREEMENTLINK}
      />
    </>
  );
}
