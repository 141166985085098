import * as FullStory from "@fullstory/browser";

const initFullStory = () => {
  window.env.ENABLE_FULL_STORY && FullStory.init({ orgId: window.env.FULLSTORY_ORG_ID });
};

const getFSQuestionCustomEvent = (stepCode: string, stepLabel: string) => {
  window.env.ENABLE_FULL_STORY &&
    FullStory.event(stepCode, {
      question_str: stepLabel,
    });
};

const setFSNavigatedToInsuranceCustomEvent = (nhDealApplicationId: string, redirectionUrl: string) => {
  window.env.ENABLE_FULL_STORY &&
    FullStory.event("NavigatedToInsurance", {
      nhDealApplicationId,
      redirectionUrl,
    });
};

const setFSUserProperties = (email?: string, lastName?: string) => {
  window.env.ENABLE_FULL_STORY &&
    email &&
    FullStory.setUserVars({
      email: email,
    });
  window.env.ENABLE_FULL_STORY &&
    lastName &&
    FullStory.setUserVars({
      lastName: lastName,
    });
};

export { initFullStory, getFSQuestionCustomEvent, setFSUserProperties, setFSNavigatedToInsuranceCustomEvent };
