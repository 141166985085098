import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_BFF_WEB_URL } from "utilities/Constant";

export interface RegisterData {
  emailaddress: string;
  password: string;
  firstname: string;
  lastname: string;
  invitationId?: string;
  loanPurpose: string;
  phoneNumber: string;
  referralSource?: string;
  referredBy?: string;
  inquiryId?: string;
  status?: string;
  leadProvider?: LeadProvider;
  nhDealApplicationId?: string;
  userRole?: RegistrationRoleType;
}

export interface RegisterResponse {
  name: string;
  id: string;
  accessToken: string;
  status: string;
  refreshToken: string;
  version: string;
  inquiryId: string;
  dealApplicationId: string;
  userRole: string;
  deviceAuthId: string;
}

export interface DecryptedUser {
  id: string;
  fn: string;
  ln: string;
  em: string;
  ph: string;
  lp: string;
  ex: boolean;
  lpn: LeadProvider;
  nhDealAppId: string;
  hp: boolean;
  rt: RegistrationRoleType;
}

interface DecryptedEmail {
  em: string;
  ex: boolean;
  hp: boolean;
  nhDealAppId: string;
}

export interface UpdatePasswordRequest {
  email: string;
  password: string;
  nhDealApplicationId: string;
}

interface UpdatePasswordResponse {
  userId: string;
  userLastName: string;
  deviceAuthId: string;
}

export enum LeadProvider {
  Default = "",
  DigitalLeads = "Digital",
  Microsite = "Microsite",
  Bonzo = "Bonzo"
}

export enum RegistrationRoleType {
  Unknown = "Unknown",
  Borrower = "Borrower",
  SpousalCoBorrower = "SpousalCoBorrower",
  NonSpousalCoBorrower = "NonSpousalCoBorrower"
}

export const registrationApi = createApi({
  reducerPath: "registrationApi",
  baseQuery: fetchBaseQuery({ baseUrl: BASE_BFF_WEB_URL }),
  endpoints: (build) => ({
    postRegistration: build.mutation<RegisterResponse, RegisterData>({
      query: (body: RegisterData) => ({
        url: "/Account/register",
        method: "POST",
        body,
      }),
    }),
    getDecryptUser: build.query<DecryptedUser, { encryptedUser?: string; email?: string; loanId?: string; leadProviderName?: string }>({
      query: ({ encryptedUser, email, loanId, leadProviderName }) => {
        const queryParams: Record<string, string> = {};
        if (encryptedUser !== undefined) queryParams['t'] = encryptedUser ?? '';
        if (email !== undefined) queryParams['email'] = email ?? '';
        if (loanId !== undefined) queryParams['loanId'] = loanId ?? '';
        if (leadProviderName !== undefined) queryParams['leadProviderName'] = leadProviderName ?? '';
        const queryString = new URLSearchParams(queryParams).toString();
        return `/user?${queryString}`;
      },
    }),
    getDecryptEmail: build.query<DecryptedEmail, string>({
      query: (encryptedUser: string) => `/user/email?t=${encryptedUser}`,
    }),
    PatchUpdatePassword: build.mutation<UpdatePasswordResponse, UpdatePasswordRequest>({
      query: (body: UpdatePasswordRequest) => ({
        url: "/Account/setpassword",
        method: "PATCH",
        body,
      }),
    }),
  }),
});

export const { usePostRegistrationMutation, useGetDecryptUserQuery, usePatchUpdatePasswordMutation, useGetDecryptEmailQuery   } = registrationApi;
