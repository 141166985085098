import { useLocation, useNavigate } from "react-router-dom";
import { ApplicationStatusNames } from "utilities/Constant";
import { useGetInterimDashboardQuery } from "pages/application/applicationService";
import { useSelector } from "react-redux";
import { getApplicationRoutingData, getNhDealApplicationId, getCurrentUserId } from "features/authSlice";
import { useEffect } from "react";

const usePerformRouting = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const authRoutingData = useSelector(getApplicationRoutingData);
  const id = useSelector(getNhDealApplicationId);
  const userId = useSelector(getCurrentUserId);
  const { data: hasInterimDashboard, isSuccess } = useGetInterimDashboardQuery({id, userId});

  const getRouteForApplicationPath = (nhApplicationId?: string): string => {
    return nhApplicationId ? "/application" : "/path";
  };

  const isNavigateToPreviewPAL = (searchParams: URLSearchParams): boolean => {
    const hasPalInstanceId = searchParams.has("palInstanceId");
    const hasId = searchParams.has("Id");
    return hasPalInstanceId && hasId && Array.from(searchParams.keys()).length > 0;
  };
  const performRouting = () => {    
    if (!isSuccess) {
      return;
    }
    const searchParams = new URLSearchParams(search);
    const { nhApplicationStatus, applicationId } = authRoutingData;

    if ((nhApplicationStatus && nhApplicationStatus.toLowerCase() === ApplicationStatusNames.Completed.toLowerCase()) || hasInterimDashboard) {
    if (!isNavigateToPreviewPAL(searchParams)) {      
          navigate("/dashboard", { replace: true });
        }        
    } else {      
      navigate(getRouteForApplicationPath(applicationId), { replace: true });
    }
  };
  useEffect(() => {
    if (isSuccess) {
      performRouting();
    }
  }, [isSuccess]); // Runs when `isSuccess` updates
  return { isSuccess, performRouting };
};

export default usePerformRouting;
