import "./uqualModal.scss";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import errorImage from "assets/images/ErrorGraphic2.png";
import nafLogoImage from "assets/images/newAmericanFundingWhiteBackground.svg";
import uqualLogoImage from "assets/images/uqual-logo.png";
import { ReactComponent as CloseIcon } from "assets/images/xmark.svg";
import Modal from "components/modal/Modal";
import { Button } from "components/ui/button/Button";
import { getNhDealApplicationId, getProspectId, setSelfReferralInSession } from "features/authSlice";
import { useSubmitUqualReferalWithConsentMutation } from "pages/application/applicationService";
import { PRIVACYLINK, UQUAL_REDIRECT } from "utilities/Constant";

interface UqualModalProps {
  onClose: () => void;
}

export default function UqualModal({ onClose }: Readonly<UqualModalProps>) {
  const [isShowDisclaimer, setIsShowDisclaimer] = useState(false);
  const [isInitialState, setIsInitialState] = useState(true);
  const [isSuccessState, setIsSuccessState] = useState(true);
  const disclaimerIcon = isShowDisclaimer
    ? solid("caret-up")
    : solid("caret-down");
  const nhDealAppId = useSelector(getNhDealApplicationId);
  const prospectId = useSelector(getProspectId);
  const [submitUqualReferal] = useSubmitUqualReferalWithConsentMutation();
  const dispatch = useDispatch();

  const handleContinueClick = async () => {
    try {
      const isSuccess = await submitUqualReferal({nhDealApplicationId:nhDealAppId,prospectId:prospectId}).unwrap();
      setIsInitialState(false);
      setIsSuccessState(isSuccess);
      dispatch(setSelfReferralInSession(true));
    } catch {
      setIsSuccessState(false);
    }
  };

  return (
    <div className="uqual-ref-modal">
<Modal
      customHeader={
        <div className="uqual-modal__close-container">
          <button
            className="uqual-modal__close-button"
            onClick={onClose}
            aria-label="Close"
          >
            <CloseIcon />
          </button>
        </div>
      }
      customBody={
        <div className="uqual-modal__content">
          {isSuccessState && (
            <>
              <div className="logos-container">
                <img
                  src={nafLogoImage}
                  alt="New American Funding Logo"
                  className="naf-logo"
                />
                <div>
                  <img
                    src={uqualLogoImage}
                    alt="Uqual Logo"
                    className="uqual-logo"
                  />
                </div>
              </div>
              {isInitialState && (
                <>
                  <h3 className="uqual-modal__title">
                    You're on the path to improving your credit!
                  </h3>
                  <h5 className="uqual-modal__subtitle">
                    Click below to receive a call from a Uqual advisor who will
                    provide a complimentary credit & financial review.
                  </h5>
                  <div className="uqual-modal__action-button">
                    <Button
                      title="Continue"
                      type="round"
                      variant="primary"
                      size="large"
                      className="button-style"
                      onClick={handleContinueClick}
                    />
                  </div>
                  <Button
                    title="Read Disclaimer"
                    type="link-no-underline"
                    icon={disclaimerIcon}
                    className={`read-disclaimer-button ${
                      isShowDisclaimer ? "show" : "hide"
                    }-disclaimer`}
                    onClick={() => setIsShowDisclaimer(!isShowDisclaimer)}
                  />
                  {isShowDisclaimer && (
                    <p className="uqual-modal__text uqual-modal__disclaimer">
                      By clicking "Continue", I acknowledge that I have reviewed
                      the <a href={PRIVACYLINK}>Privacy Policy</a> and agree by
                      electronic signature to be contacted by New American
                      Funding and its partners about a mortgage loan, real
                      estate, and/or home-related products or services that may
                      be of interest to me via email, phone, and SMS text at my
                      residential or cellular phone number, dialed manually or
                      by an autodialer. (Consent to be contacted is not a
                      condition to purchase services.) I consent to be contacted
                      even if my phone number or email address appears on a New
                      American Funding Do Not Contact/Do Not Email list, a State
                      or National Do Not Call Registry, or any other Do Not
                      Contact list.
                    </p>
                  )}
                </>
              )}
              {!isInitialState && (
                <>
                  {isSuccessState && (
                    <div className="uqual-modal__success">
                      <h5 className="uqual-modal__title thanks">
                        Thank you! An advisor will be in touch soon.
                      </h5>

                      <hr />

                      <h5 className="uqual-modal__subtitle">
                        Vanessa R., Nevada
                      </h5>

                      <p className="uqual-modal__testimonial">
                        "Chris is awesome! Currently working on getting all my
                        credit situated and also going to get my husband
                        involved, cause Lord knows we need it! So excited to get
                        the ball rolling. Thanks again, Uqual! Everyone in this
                        company and New American Funding I'm working with has
                        just had the best attitude and it makes me so
                        comfortable. Adding to note that Ruth Branham is super
                        sweet, and everyone is always so great at
                        communicating."
                      </p>
                      <div className="rating">
                        <FontAwesomeIcon icon={solid("star")} />
                        <FontAwesomeIcon icon={solid("star")} />
                        <FontAwesomeIcon icon={solid("star")} />
                        <FontAwesomeIcon icon={solid("star")} />
                        <FontAwesomeIcon icon={solid("star")} />
                      </div>
                    </div>
                  )}
                </>
              )}
            </>
          )}
          {!isSuccessState && (
            <div className="error">
              <h3 className="uqual-modal__title">Oops! Something Went Wrong</h3>
              <h5 className="uqual-modal__subtitle">
                We're having a bit of technical trouble right now. Please{" "}
                <a href={UQUAL_REDIRECT}>click here</a> to schedule your
                consultation with a Uqual Advisor. We appreciate your patience!
              </h5>
              <div className="uqual-modal__error-image">
                <img src={errorImage} alt="Error indicator graphic" />
              </div>
            </div>
          )}
        </div>
      }
    />
    </div>
  );
}
