import { getCurrentUserName } from "features/authSlice";
import NavBar from "components/ui/nav-bar/NavBar";
import { useSelector } from "react-redux";
import { Outlet, useLocation } from "react-router-dom";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import nafhubImg from "assets/images/nafhub-light.svg";
import NewHeaderLogo from "assets/images/nafhub-light-logo.svg";
import "./header.scss";
import SectionBar from "pages/application/header/section-bar/SectionBar";
import { getStepData } from "components/question/questionSlice";
import ProgressBar from "pages/application/header/progress-bar/ProgressBar";
import useDesktopChecker from "hooks/useDesktopChecker";
import { PhoneNumber, CONTACT_US } from "utilities/Constant";
import { useGetFeatureFlagsQuery } from "services/common/featureFlagService";

function Header() {
  const userName = useSelector(getCurrentUserName);
  const pathName = useLocation().pathname.toLowerCase();
  const showNavLinks = pathName.includes("/dashboard");
  const isLos = pathName.includes("/los");

  return <HeaderComponent userName={userName} showNavLinks={showNavLinks} isLos={isLos} />;
}

interface HeaderComponentProps {
  readonly userName: string;
  readonly showNavLinks: boolean;
  readonly isLos?: boolean;
}

export function HeaderComponent({ userName, showNavLinks, isLos }: HeaderComponentProps) {
  const { section: activeSection, progress: progressPercentage, sections } = useSelector(getStepData);
  const showSectionBar = useLocation().pathname.includes("/application");
  const stepData = useSelector(getStepData);
  const summaryHeader = stepData?.stepType === "summary";
  const isDesktop = useDesktopChecker();
  const { data: featureFlag } = useGetFeatureFlagsQuery();
  const contactNumber = isLos ? CONTACT_US : PhoneNumber;
  const handleClick = () => {
    if (!isDesktop) {
      window.location.href = `tel:${contactNumber}`;
    }
  };
  const pathName = useLocation().pathname.toLowerCase();
  const isDocument = location.pathname === "/dashboard/documents";
  const isHeaderWhite =
    (location.pathname === "/" && featureFlag?.newLoginUIEnabled) ||
    location.pathname === "/mfa" ||
    location.pathname === "/authcode" ||
    pathName.includes("/registration") ||
    pathName.includes("/registrationCoBorrower") ||
    pathName.includes("/registrationAuth");

  let headerLogo;
  if (isHeaderWhite) {
    headerLogo = NewHeaderLogo;
  } else {
    headerLogo = nafhubImg;
  }

  let logoClassName;
  if (isHeaderWhite) {
    logoClassName = "nafhub-logo-v2";
  } else {
    logoClassName = "nafhub-logo";
  }

  return (
    <>
      <div className={isHeaderWhite ? "new-header" : "header-container"}>
        <header className={isDocument || summaryHeader ? "document-header" : "nafhub-header"}>
          <img className={logoClassName} src={headerLogo} alt="logo" />
          {!userName && !showSectionBar && !showNavLinks && (
            <div>
              {isDesktop ? (
                <p className="nafhub-phonenumber">Need help? {contactNumber}</p>
              ) : (
                <a href={`tel:${contactNumber}`} onClick={handleClick} title="phone number">
                  <FontAwesomeIcon icon={solid("phone-flip")} className="nafhub-phonenumber" />
                </a>
              )}
            </div>
          )}
          {userName && (
            <span className={showNavLinks ? "show-links" : "hide-links"}>
              <NavBar userName={userName} showNavLinks={showNavLinks} />
            </span>
          )}
        </header>
      </div>
      {showSectionBar && (
        <>
          <ProgressBar progressPercentage={progressPercentage} />
          <SectionBar activeSection={activeSection} sections={sections} />
        </>
      )}
      <Outlet />
    </>
  );
}

export default Header;
