import TableItems from "components/Table/Table";
import Card from "components/card/Card";
import {
  useGetDashboardItemsQuery,
  DashboardItemsResponseDto,
  useSetStatusFlagOfUserMutation,
  useGetConditionsQuery,
  useSetPalStatusFlagOfUserMutation,
  useCheckCreditScoreQuery,
  PropertyInfo,
} from "./dashboardService";
import FundedState from "components/funded-state/FundedState";
import LoanProgessBar from "components/ui/loan-progress-bar/LoanProgessBar";
import { useSelector, useDispatch } from "react-redux";
import DashboardPopUp from "pages/dashboard-popup/Dashboard-popup";
import "./dashboard.scss";
import {
  getCurrentUserName,
  setLogout,
  getNhDealApplicationId,
  getHasPAL,
  getProspectId,
  getLoanPurpose,
  getSelfReferralInSession,
  getCurrentUserId,
} from "features/authSlice";
import { useNavigate } from "react-router";
import { showDocuments } from "utilities/UserversionFeatures";
import { useState } from "react";
import NafInsuranceAd from "../advertisements/NafInsurance";
import { useGetFeatureFlagsQuery } from "services/common/featureFlagService";
import { DefaultConditionsCount, LoanPurposeNames } from "utilities/Constant";
import PreApprovalPreview from "./PalPreview/PreApprovalPreview";
import UqualBanner from "../advertisements/uqual-banner/UqualBanner";
import UqualModal from "../advertisements/uqual-modal/UqualModal";

export const tooltipIndexValues = {
  initial: 0,
  conditions: 1,
  milestones: 2,
  documents: 3,
  completed: 4,
};

export default function Dashboard() {
  const userName = useSelector(getCurrentUserName);
  const nhDealApplicationId = useSelector(getNhDealApplicationId);
  const [isPalPreview, setIsPalPreview] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userId = useSelector(getCurrentUserId);
  const { data, isError, error } = useGetDashboardItemsQuery({ dealApplicationId: nhDealApplicationId, userId: userId });
  const [tooltipIndex, setTooltipIndex] = useState(tooltipIndexValues.initial);

  const toggleModal = () => setIsPalPreview(!isPalPreview);
  let component: JSX.Element | null = null;
  if (isError && typeof error === "object" && "status" in error && error.status === 401) {
    dispatch(setLogout());
    navigate("/", { replace: true, state: { path: location.pathname } });
  }
  if (data == undefined) {
    return null;
  }
  const {
    loanStatus: { status, subStatus },
    closingDate,
    propertyInfo,
  }: DashboardItemsResponseDto = data;

  const handleClickPal = () => {
    toggleModal();
    setTooltipIndex(tooltipIndexValues.completed);
  };

  const handleClosePal = () => {
    toggleModal();
    setTooltipIndex(tooltipIndexValues.conditions);
  };

  switch (status) {
    case "Processing":
    case "Underwriting":
    case "Approval":
    case "FinalReview":
    case "Close":
    default:
      component = (
        <OutStandingItemsCard
          tooltipIndex={tooltipIndex}
          setTooltipIndex={setTooltipIndex}
          isStatusInStarted={status == "Started"}
          handleClickPal={handleClickPal}
          isPalPreview={isPalPreview}
          propertyInfo={propertyInfo}
        />
      );
      break;
    case "Funded":
      component = <FundedState userName={userName.toUpperCase() + "!"} />;
      break;
  }
  return (
    <>
      {status != "Funded" && (
        <LoanProgessBar
          status={status}
          currentStatus={subStatus}
          closingDate={closingDate}
          showTooltip={tooltipIndex === tooltipIndexValues.milestones}
          tooltipIndex={tooltipIndex}
          setTooltipIndex={setTooltipIndex}
        />
      )}
      {component}
      {isPalPreview && <PreApprovalPreview onClose={handleClosePal} />}
    </>
  );
}

const OutStandingItemsCard = (props: {
  tooltipIndex: number;
  setTooltipIndex: (index: number) => void;
  isStatusInStarted: boolean;
  handleClickPal: () => void;
  isPalPreview?: boolean;
  propertyInfo?: PropertyInfo;
}) => {
  const { tooltipIndex, setTooltipIndex, isStatusInStarted, handleClickPal, isPalPreview = false, propertyInfo } = props;
  const nhDealApplicationId = useSelector(getNhDealApplicationId);
  const hasPAL = useSelector(getHasPAL);
  const userName = useSelector(getCurrentUserName);
  const prospectId = useSelector(getProspectId);
  const selfReferralInSession = useSelector(getSelfReferralInSession);
  const { pendingItems } = useGetConditionsQuery(nhDealApplicationId, {
    selectFromResult: ({ data }) => ({
      pendingItems: data
        ? data.map((item) => ({
            title: item.title,
            status: item.status,
            id: item.id,
          }))
        : [],
    }),
  });
  const userId = useSelector(getCurrentUserId);
  const { isUserNew, isGetItemError, getItemError, isPalViewed, isUqualSelfReferralDone } = useGetDashboardItemsQuery(
    { dealApplicationId: nhDealApplicationId, userId: userId },
    {
      selectFromResult: ({ data, isError, error }) => ({
        isUserNew: data!.isNew,
        isGetItemError: isError,
        getItemError: error,
        isPalViewed: data!.isPalViewed,
        isUqualSelfReferralDone: data?.isUqualSelfReferralDone,
      }),
    }
  );

  const { data: isCreditScoreLow } = useCheckCreditScoreQuery({ nhDealApplicationId: nhDealApplicationId, prospectId: prospectId });
  const [updateUserstatus, { isError, error }] = useSetStatusFlagOfUserMutation();
  const [updatePalUserStatus] = useSetPalStatusFlagOfUserMutation();
  const { data: featureFlag } = useGetFeatureFlagsQuery();
  const loanPurpose = useSelector(getLoanPurpose);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  if (isGetItemError || isError) {
    if (typeof getItemError === "object" && "status" in getItemError && getItemError.status === 401) {
      dispatch(setLogout());
      navigate("/", { replace: true, state: { path: location.pathname } });
    }
    if (typeof error === "object" && "status" in error && error.status === 401) {
      dispatch(setLogout());
      navigate("/", { replace: true, state: { path: location.pathname } });
    }
    // should add code for other errors
  }
  const handleClose = () => {
    if (hasPAL) {
      void updatePalUserStatus(nhDealApplicationId);
    } else {
      void updateUserstatus(nhDealApplicationId);
    }
    setTooltipIndex(tooltipIndexValues.conditions);
  };

  const handleClick = () => {
    handleClose();
    handleClickPal();
  };

  const shouldDisplayInsuranceAd = () => {
    const loanPurposeLower = loanPurpose.toLowerCase();
    const isRefinance = loanPurposeLower === LoanPurposeNames.Refinance.toLowerCase();
    const isPurchase = loanPurposeLower === LoanPurposeNames.Purchase.toLowerCase();
    const insuranceAdEnabled = featureFlag?.insuranceAdEnabled;
    const insuranceAdForSubPropEnabled = featureFlag?.insuranceAdForSubPropAddressEnabled;
    const hasValidStreet = propertyInfo?.street && !["", "TBD", "TBA"].includes(propertyInfo.street);

    if (!insuranceAdForSubPropEnabled && insuranceAdEnabled && !isCreditScoreLow) return true;

    if (hasValidStreet) {
      if (insuranceAdEnabled && !isCreditScoreLow) {
        if (insuranceAdForSubPropEnabled && isRefinance) return true;
        if (isPurchase && !hasValidStreet) return false;
        if (hasValidStreet) return true;
      }

      if (
        insuranceAdEnabled &&
        isCreditScoreLow &&
        isUqualSelfReferralDone &&
        !selfReferralInSession &&
        featureFlag?.uQualSelfReferralAdLogicEnabled
      ) {
        return true;
      }
    }
    return false;
  };
  const { data } = useGetDashboardItemsQuery({ dealApplicationId: nhDealApplicationId, userId: userId });
  const loanstatus = data?.loanStatus.status != "Started";
  const getConditionsTile = () => {
    return (
      <>
        <TableItems
          Tablevalues={pendingItems}
          showTooltip={tooltipIndex === tooltipIndexValues.conditions}
          setTooltipIndex={setTooltipIndex}
          isStatusInStarted={isStatusInStarted}
        />
        {shouldDisplayInsuranceAd() && <NafInsuranceAd />}
      </>
    );
  };

  const [isShowUqualModal, setIsShowUqualModal] = useState(false);
  const handleUqualClick = () => {
    setIsShowUqualModal(true);
  };

  return (
    <>
      {((isCreditScoreLow && (!isUqualSelfReferralDone || selfReferralInSession) && featureFlag?.uQualSelfReferralAdLogicEnabled) ||
        (isCreditScoreLow && !featureFlag?.uQualSelfReferralAdLogicEnabled)) && (
          <div className="dashboard-uqal-banner">
            <UqualBanner onClick={handleUqualClick} />
          </div>
        )}
      {isCreditScoreLow && isShowUqualModal && <UqualModal onClose={() => setIsShowUqualModal(false)} />}
      <div className={loanstatus || pendingItems.length == DefaultConditionsCount ? "generic-condition" : "conditionsContainer"}>
        <Card header={tooltipIndex === tooltipIndexValues.conditions ? "" : "Tasks to be completed"} body={getConditionsTile()} />
      </div>
      {showDocuments() && (
        <div className="dashboard-content">
          <p className="dashboard-content__text">* All documents uploaded by you are visible under the My Documents tab</p>
        </div>
      )}
      {!isPalPreview && (isUserNew || (!isPalViewed && hasPAL && featureFlag?.preApprovalLetterEnabled)) && (
        <DashboardPopUp
          onClose={() => {
            handleClose();
          }}
          userName={userName.split(" ")[0]}
          showNewPalPopup={!isPalViewed && hasPAL}
          handleClick={handleClick}
        />
      )}
    </>
  );
};
