import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQueryWithAuth } from "services/common/rtkQueryConfig";

export interface NotificationResponse {
  id: string;
  userId: string;
  title: string;
  text: string;
  link: string;
  createdOn: Date;
}
export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: baseQueryWithAuth, //baseQuery
  endpoints: (build) => ({
    getNotificationDetails: build.query<NotificationResponse[], string>({
      query: (userId: string) => ({
        url: `/notification/user/${userId}`,
        method: "GET",
      }),
      keepUnusedDataFor: 0,
    }),
    setStatusFlagOfNotification: build.mutation<void, string>({
      query: (id: string) => ({
        url: `/notification/${id}/isViewed-flag`,
        method: "PATCH",
      }),
    }),
  }),
});

export const { useGetNotificationDetailsQuery } = userApi;
export const { useSetStatusFlagOfNotificationMutation } = userApi;
