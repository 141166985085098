import { PropsWithChildren } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import RoundBadge from "components/ui/roundbadge/RoundBadge";
import "./summary.scss";
import {
  useGetApplicationByIdQuery,
  useSetApplicationStepAnswerMutation,
  useSetApplicationPreviousStepMutation,
  Validation
} from "pages/application/applicationService";
import { capitalizeFirstLetter } from "utilities/string";
import Profile from "./Profile";
import Credit from "./Credit";
import Property from "./Property";
import Finances from "./Finances";
import Acknowledgements from "./Acknowledgements/Acknowledgements";
import { Button } from "components/ui/button/Button";
import Modal from "components/modal/Modal";
import ToastMessage from "components/ui/toast-message/ToastMessage";
import { useSelector, useDispatch } from "react-redux";
import { getCurrentUserApplicationId, setLogout } from "features/authSlice";
import { useNavigate } from "react-router-dom";


const shouldShowValidationToast = (validation: Validation): boolean => {
  if (validation.success || !validation.message) {
    return false;
  }

  return true;
};

const Summary = () => {  
  const applicationId = useSelector(getCurrentUserApplicationId);
  const { activeSummarySection } = useGetApplicationByIdQuery(applicationId, {
    refetchOnMountOrArgChange: 1,
    selectFromResult: ({ data }) => ({
      activeSummarySection: data?.activeSummarySection,
    }),
  });

  const [_, { validation }] = useSetApplicationStepAnswerMutation({
    fixedCacheKey: "updateApplication",
    selectFromResult: (result) => ({
      validation: result.data?.validation,
    }),
  });  

  return (
    <div className="summaryContainer">

      {validation && shouldShowValidationToast(validation) && (
                <ToastMessage
                  message={validation.message}
                  timeout={3000}
                  position="top-right"
                  state="error"
                />
              )}

      <div className="summaryWrapper">
        <main className="application-summary">
          <Header />
          {sections.map(({ component, key }, index) => {
            return (
              <Section
                key={key}
                index={index + 1}
                name={capitalizeFirstLetter(key)}
                status={getStatusForSection(index, activeSummarySection!)}
              >
                {component}
              </Section>
            );
          })}
        </main>
      </div>
    </div>
  );
};

const Header = () => {
  return (
    <div className="application-summary__header">
      <PreviousQuestionButton />
      <h2>Summary</h2>
      <p>
        You can make edits to applicable sections here. Changes can also be made
        with our loan experts after submission.
      </p>
    </div>
  );
};

const PreviousQuestionButton = () => {
  const [setPreviousQuestion, { isError, error }] =
    useSetApplicationPreviousStepMutation({
      fixedCacheKey: "updateApplication",
    });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const applicationId = useSelector(getCurrentUserApplicationId);
  if (
    isError &&
    typeof error === "object" &&
    "status" in error &&
    error.status === 401
  ) {
    dispatch(setLogout());
    navigate("/", { replace: true, state: { previousPath: "/application" } });
  }
  return (
    <button
      className="previous-question-button"
      onClick={async () => {
        await setPreviousQuestion({
          id: applicationId,
        });
      }}
    >
      <FontAwesomeIcon icon={regular("arrow-left")} className="arrow-left" />{" "}
      Previous
    </button>
  );
};

type Status = "complete" | "current" | "upcoming";

const Section = ({
  index,
  name,
  status,
  children,
}: PropsWithChildren<{
  index: number;
  name: string;
  status: Status;
}>) => {
  const [_, { actionMessage }] = useSetApplicationStepAnswerMutation({
    fixedCacheKey: "updateApplication",
    selectFromResult: (result) => ({
      actionMessage: result.data?.actionMessage,
    }),
  });
  if (status === "complete") {
    return (
      <div className="summary-section summary-section-complete">
        <FontAwesomeIcon
          className="complete-solid-check"
          icon={solid("circle-check")}
        />
        <h2 className="summary-section__name-completed">{name}</h2>
      </div>
    );
  } else if (status === "current") {
    return (
      <div className="current-section">
        <div className="summary-heading">
          <div className="summary-section summary-section-current">
            <RoundBadge value={index} size="large" />
            <h1 className="summary-section__name">{name}</h1>
          </div>
          {actionMessage && (
            <span className="toast">
              <ToastMessage
                message={actionMessage}
                timeout={3000}
                state="success"
              />
            </span>
          )}
        </div>
        <section className="current-section__content">{children}</section>
      </div>
    );
  }
  return (
    <div className="summary-section summary-section-upcoming">
      <RoundBadge value={index} size="small" variant="secondary" />
      <h2 className="summary-section__name">{name}</h2>
    </div>
  );
};

export const ActionButton = ({
  label,
  stepActionId,
  action,
  toggleModal,
}: {
  label: string;
  stepActionId: string;
  action: "edit" | "remove" | "add" | "next";
  toggleModal?: () => void;
}) => {
  const [updateApplication] = useSetApplicationStepAnswerMutation({
    fixedCacheKey: "updateApplication",
  });
  const applicationId = useSelector(getCurrentUserApplicationId);
  const handleSubmit = async () => {
    if (toggleModal) {
      toggleModal();
      return;
    }
    await updateApplication({
      id: applicationId,
      stepActionId,
    });
  };

  if (action === "edit" || action === "remove") {
    return (
      <Button
        className={`${action}-details`}
        onClick={() => void handleSubmit()}
        title={label}
        type="link-no-underline"
      />
    );
  } else if (action === "add") {
    return (
      <div className="add-button-container">
        <Button
          type="link-no-underline"
          className="add-button"
          onClick={() => void handleSubmit()}
        >
          <FontAwesomeIcon
            icon={solid("plus-circle")}
            className="add-button__icon"
          />
          <span>{label}</span>
        </Button>
      </div>
    );
  }

  return (
    <Button
      className="next-button"
      onClick={() => void handleSubmit()}
      title={label}
    />
  );
};

const sections = [
  {
    component: <Profile />,
    key: "profile",
  },
  {
    component: <Property />,
    key: "property",
  },
  {
    component: <Credit />,
    key: "credit",
  },
  {
    component: <Finances />,
    key: "finances",
  },
  {
    component: <Acknowledgements />,
    key: "acknowledgements",
  },
];

const getStatusForSection = (
  keyIndex: number,
  activeSummarySection: string
): Status => {
  const activeSectionIndex = sections.findIndex(
    ({ key }) => key === activeSummarySection
  );

  if (keyIndex < activeSectionIndex) {
    return "complete";
  } else if (keyIndex === activeSectionIndex) {
    return "current";
  }
  return "upcoming";
};

interface NextModalProps {
  stepActionId: string;
  toggleModal: () => void;
  sectionName: string;
}
export const NextModal = ({
  stepActionId,
  toggleModal,
  sectionName,
}: NextModalProps) => {
  const [updateApplication] = useSetApplicationStepAnswerMutation({
    fixedCacheKey: "updateApplication",
  });
  const applicationId = useSelector(getCurrentUserApplicationId);
  const handleNext = async () => {
    await updateApplication({
      id: applicationId,
      stepActionId,
    });

    toggleModal();
  };

  return (
    <Modal
      defaultHeader
      defaultBody={false}
      defaultHandleHide={toggleModal}
      defaultHeaderName={sectionName}
      defaultHeaderIcon={regular("file-check")}
      customBody={
        <>
          <p className="custom-full-content">
            By Clicking on confirm and continue we will move to the next step
            and you will not be able to make further edits to this section.You
            will still have an opportunity to make corrections with our loan
            experts.
          </p>
        </>
      }
      footerContentPosition="right"
      footer={
        <>
          <Button
            title={"Cancel"}
            onClick={toggleModal}
            type={"round"}
            variant={"outline-primary"}
          />
          <Button
            title={"Confirm & Continue"}
            type={"round"}
            variant={"primary"}
            onClick={() => void handleNext()}
          />
        </>
      }
    />
  );
};

interface RemoveModalProps {
  toggleModal: () => void;
  label: string;
  subContents: string[];
  stepActionId: string | undefined;
}

export const RemoveModal = ({
  stepActionId,
  toggleModal,
  label,
  subContents,
}: RemoveModalProps) => {
  const [updateApplication] = useSetApplicationStepAnswerMutation({
    fixedCacheKey: "updateApplication",
  });
  const applicationId = useSelector(getCurrentUserApplicationId);
  const handleRemove = async () => {
    await updateApplication({
      id: applicationId,
      stepActionId,
    });

    toggleModal();
  };

  return (
    <Modal
      defaultHeader
      defaultBody
      defaultHandleHide={toggleModal}
      defaultBodyLabel={label}
      defaultBodySubContent={[...subContents]}
      footerContentPosition="center"
      footer={
        <>
          <Button
            title={"Cancel"}
            onClick={toggleModal}
            type={"round"}
            variant={"outline-primary"}
          />
          <Button
            title={"Remove"}
            type={"round"}
            variant={"primary"}
            onClick={() => void handleRemove()}
          />
        </>
      }
    />
  );
};

export default Summary;
