import React, { useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { passwordIcon, passwordType } from "components/password-and-confirm-password/passwordcheckerversion2/PasswordCheckerVersion2";
import { PrivacyPolicy } from "components/privacy-policy/PrivacyPolicy";
import { Button } from "components/ui/button/Button";
import Form from "components/ui/form/Form";
import { FormInput } from "components/ui/form/FormInput";
import { LoginData, usePostLoginMutation } from "./loginService";
import * as Constant from "utilities/Constant";
import { emailAddress, password_input as password } from "utilities/formSchemas";
import { LoadingOverlay } from "components/ui/loading-spinners";
import "./login.scss";
import { IMetricTelemetry } from "@microsoft/applicationinsights-web";
import { setCredentials, setIsLogin } from "features/authSlice";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { ErrorType } from "utilities/error";
import { ValidateOtpResponse } from "pages/authentication/authenticationService";
import { setFSUserProperties } from "utilities/full-story";
import { getLastName } from "utilities/string";
import useDesktopChecker from "hooks/useDesktopChecker";
import { useGetFeatureFlagsQuery } from "services/common/featureFlagService";
import NewLoginUI from "./NewLoginUI";
import { DecryptedUser } from "pages/registration/registrationService";
const formSchema = z.object({
  email: emailAddress,
  password,
});

export default function Login() {
  const location = useLocation();
  const dispatch = useDispatch();
  const pathsToRefresh = ["/application", "/dashboard"];
  if (
    location.state != null &&
    typeof location.state === "object" &&
    "previousPath" in location.state &&
    pathsToRefresh.some((str) => location.state.previousPath.includes(str)) // eslint-disable-line
  ) {
    history.replaceState({}, "", "/");
    window.location.reload();
  }
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm({
    resolver: zodResolver(formSchema),
  });
  const [error, setError] = useState(0);
  const [sendRequest, { isSuccess, data, isLoading: isLoginLoading, isError: isLoginError }] = usePostLoginMutation();
  const onSubmit = (data: LoginData) =>
    sendRequest(data)
      .unwrap()
      .catch((error: ErrorType) => {
        setError(error.status);
      });
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const navigate = useNavigate();
  const isDesktop = useDesktopChecker();

  if (isSuccess && data) {
    if ("status" in data && data.status.toLowerCase() === "completed") {
      const response = data as ValidateOtpResponse;
      const lastName = getLastName(response.name);
      setFSUserProperties(getValues("email") as string, lastName.toLowerCase());
      dispatch(setCredentials(response));
      dispatch(setIsLogin(true));
      navigate("/my-loans", { replace: true });
    } else {
      setFSUserProperties(getValues("email") as string);
      navigate("/mfa", { replace: true });
    }
  }
  const { data: featureFlag } = useGetFeatureFlagsQuery();
  if (isLoginLoading) return <LoadingOverlay text="Logging in..." />;

  const newRelicEnabled = Constant.NEW_RELIC_ENABLED;
  if (!newRelicEnabled) {
    const metricData: IMetricTelemetry = {
      average: 100,
      name: "React Component Engaged Time (seconds)",
      sampleCount: 1,
    };
    const appInsights = useAppInsightsContext();
    const additionalProperties = { "Component Name": "Login" };
    appInsights.trackMetric(metricData, additionalProperties);
  }

  const formatContent = (content: string[] | undefined) => {
    return content?.map((line) => (
      <React.Fragment key={line}>
        {line} <br />
      </React.Fragment>
    ));
  };

  const loginError = (error: number) => {
    switch (error) {
      case 401:
      case 404:
        return Constant.InvalidPassword;
      case 403:
        return Constant.MultipleAttemptFailure;
      case 500:
        return Constant.DefaultLoginError;
    }
  };
  const loginErrorMessage = (error: number) => {
    const errorMessage = loginError(error);
    return isDesktop ? <div className="error__message">{errorMessage}</div> : <div className="error__message">{formatContent(errorMessage)}</div>;
  };

  const userDetails = location.state as DecryptedUser;
  return (
    <div className="login">
      {featureFlag?.newLoginUIEnabled ? (
        <NewLoginUI
          isLoginError={isLoginError}
          loginErrorMessage={loginErrorMessage}
          handleSubmit={handleSubmit}
          register={register}
          errors={errors}
          onSubmit={onSubmit}
          isPasswordVisible={isPasswordVisible}
          setIsPasswordVisible={setIsPasswordVisible}
          error={error}
          userDetails={userDetails}
        />
      ) : (
        <div className="container">
          <h1 className="login-header">Welcome Back</h1>
          <div>
            <p className="sub-header sub-header--primary margin-top-small margin-bottom-medium">
              Please sign in to access your New American Funding application
            </p>
            {isLoginError && <div className="error__message">{formatContent(loginError(error))} </div>}
          </div>

          <div className="form-container">
            <Form
              onSubmit={handleSubmit(async (data) => {
                await onSubmit({
                  emailaddress: data.email as string,
                  password: data.password as string,
                });
              })}
            >
              <FormInput
                formHandle={register("email")}
                inputProps={{
                  name: "email",
                  placeholder: "Email",
                  label: "Email",
                  errorMessage: errors.email?.message as string,
                }}
              />
              <FormInput
                formHandle={register("password")}
                inputProps={{
                  name: "password",
                  type: passwordType(isPasswordVisible),
                  placeholder: "Password",
                  icon: passwordIcon(isPasswordVisible),
                  onIconClick: () => setIsPasswordVisible(!isPasswordVisible),
                  isIconPointer: true,
                  label: "Password",
                  errorMessage: errors.password?.message as string,
                }}
              />
              <Button title="Login" className="login-btn" />
            </Form>
          </div>
          <div className="login-bottom">
            <a href="/forgotpassword" className="forgot-password">
              Forgot Password?
            </a>
            <span>
              By continuing you are agreeing to the {""}
              <a href={Constant.TERMSOFUSELINK} target="_blank" rel="noreferrer">
                Terms of Use
              </a>
            </span>
          </div>
        </div>
      )}
      <PrivacyPolicy privacyLink={Constant.PRIVACYLINK} />
    </div>
  );
}
