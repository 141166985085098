import { Button } from "components/ui/button/Button";
import "./naf-insurance.scss";
import nafInsuranceLogo from "assets/images/naf-insurance-blue.png";
import { useSelector } from "react-redux";
import { getLoanPurpose, getNhDealApplicationId } from "features/authSlice";
import {
  EXTERNAL_REDIRECTION_TOKEN,
  EXTERNAL_REDIRECTION_TYPE_INSURANCE,
  LoanPurposeNames,
  NafInsurance_Loader_Header_desktop,
  NafInsurance_Loader_Header_Mobile_Part1,
  NafInsurance_Loader_Header_Mobile_Part2,
  NafInsuranceAd_Disclaimer_Content,
  NafInsuranceAd_Disclaimer_Title,
  NafInsuranceAd_Loader_Message1,
  NafInsuranceAd_Loader_Message2,
  NafInsuranceAd_Purchase,
  NafInsuranceAd_Refinance,
} from "utilities/Constant";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useMaticResponseMutation } from "../home/dashboardService";
import { setFSNavigatedToInsuranceCustomEvent } from "utilities/full-story";
import { useGetFeatureFlagsQuery } from "services/common/featureFlagService";
import useDesktopChecker from "hooks/useDesktopChecker";
import { Loader } from "components/Loader/Loader";

export default function NafInsuranceAd() {
  const loanPurpose = useSelector(getLoanPurpose);
  const isRefinance = loanPurpose.toLowerCase() == LoanPurposeNames.Refinance.toLowerCase();
  const isPurchase = loanPurpose.toLowerCase() == LoanPurposeNames.Purchase.toLowerCase();
  const nhDealApplicationId = useSelector(getNhDealApplicationId);
  const isDesktop = useDesktopChecker();
  const [showDisclaimerToolTip, setShowDisclaimerToolTip] = useState(false);
  const [maticResponse, { isLoading }] = useMaticResponseMutation();
  const { data: featureFlag } = useGetFeatureFlagsQuery();

  const getDisclaimerContent = () => {
    return (
      <div className={"naf-insurance__content__disclaimer__tooltip" + (isRefinance ? " refinance" : " purchase")}>
        <FontAwesomeIcon icon={solid("xmark")} className="naf-insurance__content__disclaimer__tooltip__close" onClick={handleDisclaimerClose} />
        <div className="naf-insurance__content__disclaimer__tooltip__content">{NafInsuranceAd_Disclaimer_Content}</div>
      </div>
    );
  };

  const handleDisclaimerClick = () => {
    setShowDisclaimerToolTip(true);
  };

  const handleDisclaimerClose = (event: React.MouseEvent) => {
    event.stopPropagation();
    setShowDisclaimerToolTip(false);
  };

  const loadMaticLandingURL = async () => {
    if (featureFlag?.insuranceQuickNewTabEnabled) {
      await loadMaticLandingUrlQuickNewTab();
    } else {
      const maticRedirectURL = await fetchMaticRedirectURL();
      setFSNavigatedToInsuranceCustomEvent(nhDealApplicationId, maticRedirectURL);
      openInNewTab(maticRedirectURL);
    }
  };

  const loadMaticLandingUrlQuickNewTab = async () => {
    try {
      localStorage.setItem(EXTERNAL_REDIRECTION_TOKEN, EXTERNAL_REDIRECTION_TYPE_INSURANCE);
      const newTab = openInNewTab("/external");
      const maticRedirectURL = await fetchMaticRedirectURL();
      if (newTab) {
        setFSNavigatedToInsuranceCustomEvent(nhDealApplicationId, maticRedirectURL);
        newTab.location.href = maticRedirectURL;
      }
    } catch (error) {
      console.error("Error in loading quick new tab URL:", error);
      localStorage.removeItem(EXTERNAL_REDIRECTION_TOKEN);
    }
  };

  const fetchMaticRedirectURL = async () => {
    const response = await maticResponse(nhDealApplicationId).unwrap();
    return response;
  };

  const openInNewTab = (url: string) => {
    const newTab = window.open(url, "_blank");
    if (!newTab) {
      console.error("Failed to open a new tab. The browser might be blocking pop-ups.");
    }
    return newTab;
  };

  return (
    <div className="naf-insurance">
      <div className="naf-insurance__left-wrapper">
        <div className="naf-insurance__logo">
          <img src={nafInsuranceLogo} alt="naf-insurance logo" />
        </div>
        <div className="naf-insurance__content">
          {isPurchase && NafInsuranceAd_Purchase}
          {isRefinance && NafInsuranceAd_Refinance}
          <span onClick={handleDisclaimerClick} className="naf-insurance__content__disclaimer">
            {NafInsuranceAd_Disclaimer_Title}
            {showDisclaimerToolTip && <>{getDisclaimerContent()}</>}
          </span>
        </div>
      </div>
      <div className="naf-insurance__cta">
        <Button title="Get Quotes" type="round" variant="primary" onClick={loadMaticLandingURL} />
      </div>
      {isLoading && !featureFlag?.insuranceQuickNewTabEnabled && (
        <Loader
          header={
            isDesktop ? (
              <>{NafInsurance_Loader_Header_desktop}</>
            ) : (
              <>
                {NafInsurance_Loader_Header_Mobile_Part1} <br />
                {NafInsurance_Loader_Header_Mobile_Part2}
              </>
            )
          }
          message1={NafInsuranceAd_Loader_Message1}
          message2={NafInsuranceAd_Loader_Message2}
        />
      )}
    </div>
  );
}
